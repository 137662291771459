import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-5">
      <div class="elfsight-app-2a3db9d7-ae98-43bf-ab4a-6f8212f3c178" ></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
