import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
import { Link } from 'react-router-dom';
// import CountUp from 'react-countup';



const CounterUp = ({ image }) => {

    const { rpdata } = useContext(GlobalDataContext);


    const counterInfo = [
        {
            title: 'Happy Hours',
            link: "/happy-hour"

        },
        {
            title: 'Catering Services',
            link: "/catering-services"
        },
    ]

    return (
        <div className='bgCountent' style={{ backgroundImage: `url("${image}")` }}>
            {/* <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(5).png?alt=media&token=c9af34df-44bf-4595-909a-86f0c9ab5d30"}
                alt='no found'
                loading='lazy'
                className='absolute top-0 left-0 w-[100%] h-[6%]'
            /> */}

            {/* <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(6).png?alt=media&token=89428890-d8bb-4227-8838-dbffb470c309"}
                alt='no found'
                loading='lazy'
                className='absolute bottom-0 left-0 w-[100%] h-[6%]'
            /> */}
            <h2 className='text-center py-4 relative text-white px-5 md:px-[20%]'>{rpdata?.dbSlogan?.[4].slogan}</h2>
            <div className='w-1/2 gap-3 rounded-2xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
                {
                    counterInfo.map((items, index) => {
                        const colorContent = index % 2 === 0 ? 'bgColor1' : 'bgColor2'
                        return (
                            <div key={index} className={`text-center rounded-3xl p-8 relative ${colorContent}`}>
                                <Link className='w-full h-full bg-green-600' to={items.link}>
                                    <p className='text-white font-extrabold text-[28px]'>{items.title}</p>
                                </Link>

                            </div>
                        )
                    })
                }
                <div>

                </div>
            </div>
        </div>
    )
}
export default CounterUp
