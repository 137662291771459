import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroVideo";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_8";
import BlockSecondary from "../components/block/Block_7";
import BlockAbout from "../components/block/Block_4";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
// import Modal from "../components/Home/Modal";
import ValuesOne from "../components/values/Values_2";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        {/* <Modal /> */}

        <HeroSection 
          urlVideo={`https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/Presentacion%20Tacos2.mp4?alt=media&token=d902c2c4-2718-455d-ba48-81ccfde121e7&_gl=1*15ujyd4*_ga*MTAwNDE4MjE4NS4xNjc0NTc5MzQw*_ga_CW55HF8NVT*MTY5ODY4NTQwNi4xMTAuMS4xNjk4Njg2MjA2LjM5LjAuMA..`}
        />

        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[7]}
          image2={"https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/post-de-tacos-01-01.png?alt=media&token=81899ea1-2bdb-4896-8bd2-a7d01a1c9a5e"}
          image3={rpdata?.gallery?.[5]}
          image4={rpdata?.gallery?.[3]}
          title={rpdata?.dbSlogan?.[10]?.slogan}

        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
              
            />
            : null
        }

        <CounterUp image={rpdata?.gallery?.[7]} />

        <Directories />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[9]}
          image2={rpdata?.gallery?.[4]}
          image3={rpdata?.gallery?.[8]}
        />

        {/* <BlockThird
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.stock?.[1]}
        /> */}

        <ValuesOne image={rpdata?.gallery?.[1]} />



        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[1]?.text}
          image1={rpdata?.gallery?.[2]}
          image2={rpdata?.gallery?.[10]}
          image3={rpdata?.gallery?.[5]}
          listAbout={true}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Gallery cuando es OnePages */}

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }
        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.stock?.length > 0 ? rpdata?.stock : rpdata?.stock} />
            </div>
            :
            null

        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }

          <div className="py-10">
            <FormHome />
          </div>


        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
