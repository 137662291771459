import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader, img }) => {
  return (
    <div
      className="breadcrumb-area flex-grow md:flex gap-10 justify-center items-center"
      style={{
        backgroundImage: `url("${
          bgimg
            ? bgimg
            : "https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/imagenes_fondos%2Ffondo_web_Mesa%20de%20trabajo%201_Mesa%20de%20trabajo%201.png?alt=media&token=1afe4a81-24bd-4d3c-9f70-bda27af71692&_gl=1*1xebk4*_ga*MTAwNDE4MjE4NS4xNjc0NTc5MzQw*_ga_CW55HF8NVT*MTY5ODY3OTkwNC4xMDkuMS4xNjk4NjgxOTI1LjUxLjAuMA.."
        }")`,
      }}
    >
        <div className="md:w-[50%] text-center">
          <h1 className="text-white px-5 md:px-[20%] pb-5">{headertitle}</h1>
          {headertitle === Subheader ? (
            <h6 className="text-white">Home - {Subheader}</h6>
          ) : (
            <h6 className="text-white">{Subheader}</h6>
          )}
        </div>
        <div className="my-10 mx-5 md:w-[50%]">
        <img
          src={img}
          className="rounded-full w-[500px] h-[400px] md:h-[500px] border-8"
          alt=""
        />
        </div>
      </div>

  );
};

export default TransparentHeader;
